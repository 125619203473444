<template>
  <v-data-table
    v-if="items" 
    :headers="headers"
    :items="items"
    :items-per-page="20"
    class="elevation-1"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-spacer/>
          <v-btn 
            v-if="items.filter(x => x.edited).length === 0"
            color="primary" 
            dark 
            @click="handleAddItem"
            dense
          >
            Añadir
          </v-btn>
      </v-toolbar>
    </template>
  <template v-slot:item.Name="{ item }">
    <span
      v-if="!item.edited"
    >
    {{getTranslation(item.Name, locale)}}
    </span>
    <translate
      v-else
      v-model="item.Name"
      :languages="languages"
      :label="$t('booking.name', locale)"
    />
    <!--v-text-field
      v-else
      v-model="item.Name"
      outlined
      small
      dense
      hide-details
    /-->
  </template>
  <template v-slot:item.Type="{ item }">
    <span
      v-if="!item.edited"
    >
      {{ getType(item.Type) }}
    </span>
    <v-select
      v-else
      :items="types"
      v-model="item.Type"
      outlined
      hide-details
      dense
      itemText="name"
      itemValue="id"
    />
  </template>
  <template v-slot:item.ORD="{ item }">
    <span
      v-if="!item.edited"
    >
      {{item.ORD}}
    </span>
    <v-text-field
      v-else
      v-model="item.ORD"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.ORD="{ item }">
    <span
      v-if="!item.edited"
    >
      {{item.ORD}}
    </span>
    <v-text-field
      v-else
      v-model="item.ORD"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="item.edited === null || item.edited === undefined"
        small
        class="mr-2"
        @click="handleEditItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        v-if="item.edited === null || item.edited === undefined"
        small
        @click="handleDeleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
        v-if="item.edited"
        small
        @click="handleClose"
      >
        mdi-close
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import utils from '@/services/utils'
import Translate from '@/components/Translate'
export default {
  components: {
    Translate,
  },
  props: {
    editedItem: {
      type: Array,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    items: null,
  }),
  computed: {
    headers () {
      return [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        { text: 'Tipo', value: 'Type' },
        { text: 'Orden', value: 'ORD' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
    types () {
      return [
        {
          id: 'text',
          name: 'texto corto'
        },
        {
          id: 'textarea',
          name: 'texto largo'
        },
        {
          id: 'link',
          name: 'link'
        }
      ]
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareData () {
      if (!this.editedItem) return
      let aux = JSON.parse(JSON.stringify(this.editedItem))
      this.items = aux.map(item => {
        item.Name = item.Name ? JSON.parse(item.Name) : null
        return item
      })
    },
    handleAddItem () {
      this.items.push({
        ID: utils.getNewID(),
        Name: { es: 'Nombre' },
        Type: 'text',
        ORD: this.items.length,
        edited: true
      })
    },
    handleEditItem (v) {
      this.items = this.items.map(item => {
        item.edited = item.ID === v.ID ? true : false
        return item
      })
    },
    handleDeleteItem (v) {
      this.items = this.items.filter(x => x.ID !== v.ID)
    },
    handleClose () {
      this.items = this.items.map(item => {
        item.edited = null
        return item
      })
      this.handleChange()
    },
    getType (v) {
      return this.types.filter(x => x.id === v).map(item => item.name).shift()
    },
    handleChange () {
      this.onChange(this.items)
    }
  }
}
</script>

