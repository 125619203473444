<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t(`booking.attendance.title`, locale) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              v-if="editedItem"
            >
              <v-col
                cols="12"
                sm="12"
                style="padding: 20px 25px 0 10px;"
              >
                <translate
                  v-model="editedItem.Name"
                  :languages="languages"
                  :label="$t('booking.name', locale)"
                  type="text"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                style="padding: 20px 15px 0 0;"
              >
                <v-select
                  :items="types"
                  label="Tipo"
                  outlined
                  v-model="editedItem.Type"
                  hide-details
                />
              </v-col>
              <v-col
                v-if="['select'].indexOf(editedItem.Type) >= 0"
                cols="12"
                sm="12"
                style="padding: 20px 15px 0 0;"
              >
                <div
                    style="padding: 10px;border-radius:10px;background-color: #CCCCCC80;"
                >
                  <small style="color: gray;">Values</small>
                  <list
                    :data="editedItem.Values"
                    :locale="locale"
                    :forceReload="forceReload"
                    :languages="languages"
                    :onChange="handleChangeValues"
                  />
                </div>
              </v-col>
              <v-col
                v-if="editedItem.Type === 'terms'"
                cols="12"
                sm="12"
                style="padding: 20px 25px 0 10px;"
              >
                <translate
                  v-model="editedItem.TermsData"
                  :languages="languages"
                  label="Texto términos"
                  type="richText"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                style="padding: 10px 15px 0 0;"
              >
                <v-checkbox
                  v-model="editedItem.Required"
                  hideDetails
                  label="Requerido"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                style="padding: 0px 15px 0 0;"
              >
                <v-checkbox
                  v-model="editedItem.ExpandData"
                  hideDetails
                  label="Mostrar solo al expandir"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="width: 100%;">
          <v-spacer />
          <action-buttons
            v-if="editedItem"
            :id="editedItem.ID"
            :onDeleteItem="(v) => {onDelete(v)}"
            :onSaveItem="handleSave"
            :onClose="onClose"
            :locale="locale"
            :icon="true"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
//import api from '@/services/api'
//import utils from '@/services/utils'
import Translate from '@/components/Translate'
import ActionButtons from '@/components/ActionButtons'
import List from './values/List'
export default {
  components: {
    Translate,
    List,
    ActionButtons,
  },
  props: {
    editedItem: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      default: null,
    },
    languages: {
      type: Array,
      default: null,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    showDialog: false,
    loading: false,
    showDialogValue: false,

    forceReload: null,
  }),
  computed: {
    types () {
      return ['string', 'select', 'phone', 'language', 'boolean', 'date', 'terms']
    },
  },
  watch: {
    dialog () {
      this.showDialog = this.dialog
    },
  },
  mounted () {
    this.showDialog = this.dialog
  },
  methods: {
    handleSave () {
      this.onSave(this.editedItem)
    },
    handleChangeValues(v) {
      this.editedItem.Values = JSON.parse(JSON.stringify(v))
    },
  },
}
</script>

